@font-face {
  font-family: "Helvetica Neue";
  src: url("assets/fonts/HelveticaNeue.ttf") format("TrueType");
}

body {
  margin: 0;
  font-family: "Helvetica Neue", "-apple-system", "BlinkMacSystemFont",
    "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji";
  background-color: #0b3e98;
}

.Toastify__toast--dark {
  background-color: black !important;
  color: white !important;
}

.Toastify__close-button {
  color: white !important;
}
.Toastify__progress-bar {
  background-color: white !important;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
